<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <div class="actions-tab actions-tab-budget-forms">
                <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret ref="dropdown" @shown="adjustDropdownMenuStyle">
                    <template #button-content>
                        <div class="btn-download">
                            <div>
                                <span class="left">
                                    <i class="icon icon-download"></i> {{ getRepText('download') }}
                                </span>
                                <i class="icon icon-keyboard"></i>
                            </div>
                        </div>
                    </template>
                    <b-dropdown-item @click="onDownloadRep(false)"> {{ formReport }}</b-dropdown-item>
                    <b-dropdown-item @click="downloadRepSum"> {{ formSumReport }}</b-dropdown-item>
                    <b-dropdown-item v-if="isDownloadRepBtnVisible" @click="downloadRepChange">{{ getFormText('change_rep_2') }}</b-dropdown-item>
                    <b-dropdown-item @click="onDownloadRep(true, true)" :disabled="$store.state.isDownloadButtonEnabled">
                        {{$store.state.isDownloadButtonEnabled ? getRepText('uploadIsProgress') : getRepText('batchUploadGuButton') }}
                    </b-dropdown-item>
                    <b-dropdown-item 
                        @click="onDownloadRep(true, false)" 
                        :disabled="$store.state.isDownloadButtonEnabled" 
                        v-if="isABPReportAvailable">
                            {{$store.state.isDownloadButtonEnabled ?  getRepText('uploadIsProgress') : getRepText('batchUploadAbpButton') }}
                    </b-dropdown-item>
                    <b-dropdown-item 
                        v-if="isParentGuExist" 
                        @click="onDownloadRep(true, false, 'edu')" 
                        :disabled="$store.state.isDownloadButtonEnabled"> 
                            {{ batchReportEducationDepText }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <b-tabs v-model="tabIndex">
            <div class="filter-container">
                <div class="left-content">
                    <budget-header v-show="tabIndex === 0" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                </div>
                <div class="right-content">
                    <div class="filter-actions filter-actions-flex">
                        <b-button :style="{ 'minWidth': '110px', 'margin-right': '15px' }" variant="primary" @click="tabIndex === 0 ? addRegion(prForm) : addRegion(budgetForm)" :disabled="tabIndex === 0 ? isLoadProject || !variantAttribute : isLoad || !variantAttribute">{{ messages.addRegion }}</b-button>
                        <c-budget-forms-copy-data
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetFormToCopy"
                            :header="header"
                            :isLoad="isLoad"
                            @keyPress="keyPress"
                        />
                        <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareSave" :disabled="tabIndex === 0 ? isLoadProject || !variantAttribute : isLoad || !variantAttribute">{{ getCommonText('save') }}</b-button>
                    </div>
                </div>
            </div>

            <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                                :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
            <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
            ></b-progress>
            <!---Вкладка "Проекты"--->
            <b-tab :title="getFormText('projects')" active>
                <div class="table-container">
                    <table class="table" responsive bordered head-variant="light" sticky-header="true" border-collapse="collapse">
                        <thead>
                            <tr>
                                <th v-for="field in prFields" :key="field.key">{{ field.label }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="td-numbering green">1</td>
                                <td class="td-numbering red">2</td>
                                <td class="td-numbering purple">3</td>
                                <td class="td-numbering orange">4</td>
                                <td class="td-numbering blue">5</td>
                                <td class="td-numbering green">6</td>
                                <td class="td-numbering red">7</td>
                                <td class="td-numbering light"></td>
                            </tr>
                            <template v-for="reg in prForm.filter(item => item.src_region)">
                                <tr v-for="item in getFilteredPrForm(prForm, reg)" :key="item.index">
                                    <!---Регион--->
                                    <template v-if="item.src_region">
                                        <td colspan="7" class="region-row">
                                            <b-form-select v-if="!item.hasChild && variantAttribute"
                                                    size="sm"
                                                    class="w-250px h-auto pre-wrap"
                                                    v-model="item.region"
                                                    :options="listRegPr"
                                                    @change="onChangeReg(item.region, item)">
                                            </b-form-select>
                                            <div v-else>{{ reg.region.code + " - " + item.region[`name_${lng}`] }}</div>
                                        </td>
                                        <!---Проверка на дубликаты--->
                                        <template v-if="item.checkForDuplicatesReg">
                                            <td class="region-row">
                                                <i class="icon icon-danger"></i>
                                                <div class="pop-up">
                                                    <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                                    <p>{{ getCommonText("exist") }}</p>
                                                </div>
                                            </td>
                                        </template>
                                        <!---Удаление региона--->
                                        <td class="region-row">
                                            <b-button v-if="!item.hasChild && variantAttribute"
                                                :title="getFormText('del_region')" 
                                                @click="deleteRegion(item, item.index)">
                                                <i class="icon icon-close" style="color:red"></i>
                                            </b-button>
                                        </td>
                                    </template>
                                    <!---Записи--->
                                    <template v-else>
                                        <!---Код проекта--->
                                        <td>
                                            {{ item.code }}
                                        </td>
                                        <!---Наименование проекта--->
                                        <td>
                                            <div class="column-width-100"
                                                :class="{ 'error': item.checkForDuplicates }">
                                                <b-form-textarea v-if="variantAttribute && !isProjectHasSpendings(item)"
                                                    :title="item.name_ru"
                                                    :value="item.name_ru"
                                                    @change="checkDoubles($event, item)"
                                                    @keyup.enter.exact="keyup13"
                                                    max-rows="15"
                                                    class="resize-auto-hidden">
                                                </b-form-textarea>
                                                <div v-else>
                                                    {{ item.name_ru }}
                                                </div>
                                                <!---Проверка на дубликаты--->
                                                <template v-if="item.checkForDuplicates">
                                                    <i class="icon icon-danger"></i>
                                                    <div class="pop-up">
                                                        <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                                        <p>{{ getCommonText("exist") }}</p>
                                                    </div>
                                                </template>
                                            </div>
                                        </td>
                                        <!---Ед. измерения--->
                                        <td>
                                            <b-form-select v-if="variantAttribute && !item.hasSpendings"
                                                size="sm"
                                                class="w-200px h-auto pre-wrap"
                                                v-model="item.unit"
                                                :options="listUnit"
                                                @change="item.unit_code = item.unit.code">
                                            </b-form-select>
                                            <div v-else>
                                                {{ getUnitLockName(item.unit) }}
                                            </div>
                                        </td>
                                        <!---Протяженность--->
                                        <td>
                                            <template v-if="item.unit !== null && variantAttribute && !item.hasSpendings">
                                                <div :class="{ 'error': ['001.006.008','002.055'].includes(item.unit.code) && (item.distance === 0)}">
                                                    <b-form-input class="text-right"
                                                        :value="item.distance"
                                                        @change="v => item.distance = v"
                                                        @keyup.enter.exact="keyup13"
                                                        @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                                        @blur="inputFixedVldtn(item, 'distance', item.distance, 3)">
                                                    </b-form-input>
                                                    <i class="icon icon-danger" v-if="['001.006.008','002.055'].includes(item.unit.code) && (item.distance === 0)"></i>
                                                    <div class="pop-up" v-if="['001.006.008','002.055'].includes(item.unit.code) && (item.distance === 0)">
                                                        <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                                        <p>{{ getFormText('not_zero', {not_equal: `&ne;` }) }}</p>
                                                    </div>
                                                </div>
                                            </template>
                                            <div v-else-if="item.unit !== null">
                                                {{ item.distance }}
                                            </div>
                                        </td>
                                        <!---Охват населения--->
                                        <td>
                                            <b-form-input v-if="variantAttribute && !item.hasSpendings"
                                                class="text-right"
                                                :value="item.population"
                                                @change="v => item.population = v"
                                                @keyup.enter.exact="keyup13"
                                                @keypress="keyPress($event, '^[0-9]+$')"
                                                @blur="inputFixedVldtn(item, 'population', item.population, 0)">
                                            </b-form-input>
                                            <div v-else>
                                                {{ item.population }}
                                            </div>
                                        </td>
                                        <!---Дата начала--->
                                        <td>
                                            <template v-if="variantAttribute && !item.hasSpendings">
                                                <div :class="{'error': item.beg_date >= item.end_date}">
                                                    <c-date-picker
                                                        :item="item" 
                                                        :header="header"
                                                        dateField="beg_date"
                                                        startEnd="start">
                                                    </c-date-picker>
                                                    <i class="icon icon-danger" v-if="item.beg_date && item.beg_date >= item.end_date"></i>
                                                    <div class="pop-up" v-if="item.beg_date >= item.end_date">
                                                        <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                                        <p>{{ `${getCommonText("beg_date")} &lt; ${getCommonText("end_date")}` }}</p>
                                                    </div>
                                                </div>
                                            </template>
                                            <div v-else>
                                                {{ item.beg_date }}
                                            </div>
                                        </td>
                                        <!---Дата окончания--->
                                        <td>
                                            <c-date-picker v-if="variantAttribute && !item.hasSpendings"
                                                :item="item"
                                                :header="header"
                                                dateField="end_date"
                                                startEnd="end">
                                            </c-date-picker>
                                            <div v-else>
                                                {{ item.end_date }}
                                            </div>
                                        </td>
                                        <!---Удаление проекта--->
                                        <td>
                                            <b-button v-if="!item.hasSpendings && variantAttribute" @click="deletePr(item, item.index)">
                                                <i class="icon icon-clear" style="color:orange"></i>
                                            </b-button>
                                            <b-button v-else-if="variantAttribute" @click="onShowSpndings(item)">
                                                <i :title="getFormText('has_spndgs')"
                                                class="icon icon-info"></i>
                                            </b-button>
                                        </td>
                                    </template>
                                </tr>
                                <!---Добавление проекта--->
                                <tr :key="'i_'+reg.index">
                                    <td colspan="8" class="add-button">
                                        <i class="icon icon-plus-circle" style="color:green"/> 
                                        <span class="add-project"
                                            v-if="reg.src_region"
                                            @click="addProject(reg)"
                                            style="color:green"
                                            :title="messages.addProject">
                                            {{ messages.addProject }}
                                        </span>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <c-spendings-modal :header="header" ref="spendingsModal"/>
            </b-tab>
            <!---Вкладка "Форма ввода"--->
            <b-tab :title="getFormText('input_form')">
                <div class="table-container">
                    <table class="table" responsive bordered head-variant="light" sticky-header="true" border-collapse="collapse">
                        <thead>
                            <tr>
                                <th v-for="field in tableFields" :key="field.key">{{ field.label }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="td-numbering light"></td>
                                <td class="td-numbering green">1</td>
                                <td class="td-numbering red">2</td>
                                <td class="td-numbering purple">3</td>
                                <td class="td-numbering orange">4</td>
                                <td class="td-numbering blue">5</td>
                                <td class="td-numbering green">6</td>
                                <td class="td-numbering red">7</td>
                                <td class="td-numbering blue">8</td>
                                <td class="td-numbering orange">9</td>
                                <td class="td-numbering light"></td>
                            </tr>
                            <template v-for="reg in budgetForm.filter(item => item.src_region)">
                                <tr v-for="item in getFilteredBudgetForm(budgetForm, reg)" :key="item.index">
                                <!---Регион--->
                                <template v-if="item.src_region">
                                    <td></td>
                                    <!---Наименование региона--->
                                    <td colspan="9"
                                        class="column-width-100 region-row"
                                        :class="{ 'error': item.checkForDuplicatesReg }">
                                        <b-form-select v-if="!item.hasChild && variantAttribute"
                                                    size="sm"
                                                    class="w-250px h-auto pre-wrap"
                                                    v-model="item.region"
                                                    :options="listReg"
                                                    @change="onChangeReg(item.region, item)"
                                        >
                                        </b-form-select>
                                        <div v-else>
                                            {{ item.region.text }}
                                        </div>
                                        <!---Проверка на дубликаты--->
                                        <template v-if="item.checkForDuplicatesReg">
                                            <i class="icon icon-danger"></i>
                                            <div class="pop-up">
                                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                                <p>{{ getCommonText("exist") }}</p>
                                            </div>
                                        </template>
                                    </td>
                                    <!---Удаление региона--->
                                    <td v-if="variantAttribute" class="region-row">
                                        <b-button
                                            :title="getFormText('del_region')" 
                                            @click="deleteRegion(item, item.index)">
                                            <i class="icon icon-close" style="color:red"></i>
                                        </b-button>
                                    </td>
                                </template>
                                <!---Записи--->
                                <template v-else>
                                    <td></td>
                                    <!---Код проекта--->
                                    <td>
                                        {{ item.code }}
                                    </td>
                                    <!---Наименование проекта--->
                                    <td
                                        class="column-width-100"
                                        :class="{ 'error': item.checkForDuplicates }">
                                        <b-form-select v-if="variantAttribute"
                                                    size="sm"
                                                    class="w-200px h-auto pre-wrap"
                                                    v-model="item.name_ru"
                                                    :options="item.projects"
                                                    @change="onChangeDef(item.name_ru, item, reg)"
                                        >
                                        </b-form-select>
                                        <div v-else>
                                            {{ item.name_ru.name_ru }}
                                        </div>
                                        <!---Проверка на дубликаты--->
                                        <template v-if="item.checkForDuplicates">
                                            <i class="icon icon-danger"></i>
                                            <div class="pop-up">
                                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                                <p>{{ getCommonText("exist") }}</p>
                                            </div>
                                        </template>
                                    </td>
                                    <!---Ед. измерения--->
                                    <td>
                                        <div v-if="item.unit !== null">
                                            {{  getUnitLockName(item.unit) }}
                                        </div>
                                    </td>
                                    <!---Протяженность--->
                                    <td>
                                        <div class="text-right">
                                            {{ item.distance }}
                                        </div>
                                    </td>
                                    <!---Охват населения--->
                                    <td>
                                        <div class="text-right">
                                            {{ item.population }}
                                        </div>
                                    </td>
                                    <!---Сумма расходов--->
                                    <td>
                                        <template v-if="variantAttribute">
                                            <div :class="{ 'error': item.cost_amount === 0}">
                                                <b-form-input
                                                    class="text-right"
                                                    :value="item.cost_amount"
                                                    @change="v => item.cost_amount = v"
                                                    @keyup.enter.exact="keyup13"
                                                    @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                                    @blur="inputFixedVldtn(item, 'cost_amount', item.cost_amount, 3)">
                                                </b-form-input>
                                                <i class="icon icon-danger" v-if="item.cost_amount === 0"></i>
                                                <div class="pop-up" v-if="item.cost_amount === 0">
                                                    <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                                    <p>{{ getFormText('spngs_not_zero', {not_equal: '&ne;'}) }}</p>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div>
                                                {{ item.cost_amount }}
                                            </div>
                                        </template>
                                    </td>
                                    <!---Программа--->
                                    <td>
                                        <b-form-select v-if="variantAttribute"
                                            size="sm"
                                            class="w-200px h-auto pre-wrap"
                                            v-model="item.program"
                                            :options="listProgram"
                                        >
                                        </b-form-select>
                                        <div v-else>
                                            {{ getLocProgramName(item.program) }}
                                        </div>
                                    </td>
                                    <!---Примечание--->
                                    <td>
                                        <textarea v-if="variantAttribute"
                                            style="height: 100%"
                                            cols="50"
                                            v-model="item.note"
                                            @keyup.enter.exact="keyup13">
                                        </textarea>
                                        <div v-else>
                                            {{ item.note }}
                                        </div>
                                    </td>
                                    <!---Файлы--->
                                    <td>
                                        <span class="blue pointer underline"
                                            @click="onFilesClick(item)">
                                            ({{item.files}})
                                        </span>
                                    </td>
                                    <!---Удалить запись--->
                                    <td>
                                        <b-button v-if="variantAttribute" @click="deleteItem(item, item.index)">
                                            <i class="icon icon-clear" style="color:orange"></i>
                                        </b-button>
                                    </td>
                                </template>
                                </tr>
                                <!---Добавить запись--->
                                <tr :key="'j_'+reg.index">
                                    <td colspan="11" class="add-button">
                                        <i class="icon icon-plus-circle" style="color:green"/> 
                                        <span class="add-project"
                                            v-if="reg.src_region"
                                            @click="addItem(reg)"
                                            style="color:green"
                                            :title="messages.addProject">
                                            {{ messages.addProject }}
                                        </span>
                                    </td>
                                </tr>
                            </template>
                            <tr>
                                <td class="text-right" colspan="4">{{ getCommonText("total") }}</td>
                                <td class="text-right">{{ $n(distance) }}</td>
                                <td class="text-right">{{ $n(population) }}</td>
                                <td class="text-right">{{ $n(total) }}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <files-updown :header="header"
                              :variant-attribute="variantAttribute"
                              :load="load"
                              @getFiles="getFiles"
                              @getNewFiles="getNewFiles($event)"
                              @delFile="delFile($event)"
                              @onFilePreview="onFilePreview"
                              ref="fileUpdown"
                ></files-updown>
                <modal-files-management-nodecode
                    ref="modalFilesManagement"
                    :variant-attribute="variantAttribute"
                    @triggDownloadFile="triggDownloadFile"
                    @triggDownloadAllFiles="triggDownloadAllFiles"
                    @fileUpload="fileUpload"
                    @toggleIsAdd="toggleIsAdd($event)"
                    />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
import store from '../../services/store';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CDatePicker from "./components/c-date-picker.vue";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import CSpendingsModal from './components/c-spendings-modal.vue'

export default {
    name: 'Form02_159_2',
    components: { BudgetHeader,
                FilesUpdown,
                BudgetFormsList,
                BreadcrumbsFilter,
                ModalFilesManagementNodecode,
                CDatePicker,
                CBudgetFormsCopyData,
                CSpendingsModal,
                },
    mixins: [FormsHandlerMixin1],
    watch: {
        prForm: {
            handler: function () {
                this.dasableRegions(this.listRegPr, this.prForm)
            },
            deep: true
        },
            
        budgetForm: {
            handler: function () {
                this.dasableRegions(this.listReg, this.budgetForm)
                this.disableProjects(this.budgetForm)
            },
            deep: true
        },
    },

    data() {
        return {
            form: {
                code: '02-159_2',
                name_ru: 'РАСЧЕТ РАСХОДОВ ПО ОПЛАТЕ РАБОТ И УСЛУГ, ОКАЗАННЫХ ФИЗИЧЕСКИМИ ЛИЦАМИ, ГОСУДАРСТВЕННЫМИ ПРЕДПРИЯТИЯМИ И ОРГАНИЗАЦИЯМ С ГОСУДАРСТВЕННЫМ УЧАСТИЕМ',
                name: 'Форма 02-159 (339)',
                name_kk: 'Жеке тұлғалар және акцияларының бақылау пакеттері мемлекетке тиесілі мемлекеттік кәсіпорындар, акционерлік қоғамдар және мемлекеттік қатысу үлестерінің мөлшерлері қатысушылардың жалпы жиналысының шешімін анықтауға мүмкіндік беретін жауапкершілігі шектеулі серіктестіктер көрсеткен жұмыстарға және көрсетілетін қызметтерге төлеу жүргізу бойынша шығыстарды есептеу',
                spf: {
                    id: 67,
                    kat: 1,
                    cls: 3,
                    pcl: 330,
                    spf: 339,
                    name_ru: 'Текущие трансферты другим уровням государственного управления',
                    name_kk: null,
                    type: 4,
                    full_code: '13330339'
                }
            },
            header: null,
            budgetForm: [],
            tree: [],
            prForm: [],
            prTree: [],
            listReg: [],
            listRegPr: [],
            listUnit: [],
            listProgram: [],
            listProject: [],
            files: null,
            load: false,
            isLoadProject: false,
            isLoad: false,
            openDisabled: false,
            projects: null,
            filter: [
                { funcGr: 12,
                    abp: [{
                        gr: 12,
                        pgr: 1,
                        abp: 268,
                        'name_ru': 'Управление пассажирского транспорта и автомобильных дорог области',
                        'full_code': '26800',
                        type: 3,
                        'name_kk': 'Облыстың жолаушылар көлігі және автомобиль жолдары басқармасы',
                        'budget_level_id': 2,
                        progr: [{ 'id': 38914, 'gr': 12, 'pgr': 1, 'abp': 268, 'prg': 25, 'ppr': null, 'type': 4, 'name_ru': 'Капитальный и средний ремонт автомобильных дорог областного значения и улиц населенных пунктов', 'name_kk': 'Облыстық автомобиль жолдарын және елді-мекендердің көшелерін күрделі және орташа жөндеу', 'short_name_ru': null, 'short_name_kk': null, 'beg_date': null, 'end_date': null, 'full_code': '268250', 'develop_type': 0, 'transfer': null, 'is_sequest': null, 'budget_level_id': null, 'name': '25 - Капитальный и средний ремонт автомобильных дорог областного значения и улиц населенных пунктов' },
                            { 'id': 38919, 'gr': 12, 'pgr': 1, 'abp': 268, 'prg': 28, 'ppr': null, 'type': 4, 'name_ru': 'Реализация приоритетных проектов транспортной инфраструктуры', 'name_kk': 'Көлiк инфрақұрылымының басым жобаларын іске асыру', 'short_name_ru': null, 'short_name_kk': null, 'beg_date': null, 'end_date': null, 'full_code': '268280', 'develop_type': 0, 'transfer': null, 'is_sequest': null, 'budget_level_id': null, 'name': '28 - Реализация приоритетных проектов транспортной инфраструктуры' },
                            { 'id': 39279, 'gr': 12, 'pgr': 9, 'abp': 268, 'prg': 113, 'ppr': null, 'type': 4, 'name_ru': 'Целевые текущие трансферты нижестоящим бюджетам', 'name_kk': 'Төменгі тұрған бюджеттерге берілетін нысаналы ағымдағы трансферттер', 'short_name_ru': null, 'short_name_kk': null, 'beg_date': null, 'end_date': null, 'full_code': '268280', 'develop_type': 0, 'transfer': null, 'is_sequest': null, 'budget_level_id': null, 'name': '113 - Целевые текущие трансферты нижестоящим бюджетам' }
                        ]
                    }] 
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            tabIndex: 0,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: 0,
        };
    },
    async mounted() {
        await this.getObl();
        await this.loadRegions();
        await this.loadUnits();
        await this.loadPrograms();
    },
    methods: {
        onTab() {
            if (this.tabIndex == 0) {
                return this.prForm;
            } else {
                return this.budgetForm;
            }
        },

        hasEmptyRegions(list) {
            return list.some(item => !item.region);
        },

        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                if (!item.src_region) {
                    let num_attach_files = 0;
                    item['row_files'] = [...item['row_files'], ...files];
                    item['row_files'].forEach((file) => {
                        if (file.row_id === item['id']) {
                            num_attach_files += 1;
                        }
                    });
                    item['files'] = num_attach_files;
                }
            });
            this.$refs.modalFilesManagement.setDataChanged(true);
        },
        
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                if (!item.src_region) {
                    item['row_files'].forEach((file, index) => {
                        if (file.file_id === fileId) {
                            item['row_files'].splice(index, 1);
                        }
                    });
                    item.files = item['row_files'].filter(i => i.row_id !== null).length;
                }
            });
        },

        infoCostForm(info_id) {
            const that = this;
            Ax(
                {
                    url: '/api-py/get-info/' + info_id, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let file = '';
                    if (info_id == 12) {
                        file = 'РП_Бюджетные_заявки_форма02_159_339_дороги';
                    }
                    link.setAttribute('download', file + '.pdf');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },

        async addItem(region) {
            const item = { id: this.newRowStartId };
            this.newRowStartId--;
            this.$set(item, 'id', 0);
            this.$set(item, 'region', region.region.code);
            this.$set(item, 'projects', region.projects);
            this.$set(item, 'unit', null);
            for (const pr of item.projects) {
                this.$set(pr, 'disabled', false);
                for (const row of this.budgetForm) {
                    if (!row.src_region) {
                        if (pr.value.code === row.code) {
                            this.$set(pr, 'disabled', true);
                        }
                    }
                }
            }
            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicates', false);
            this.$set(item, 'files', 0);
            let j = 0
            if (this.budgetForm.length != 0){
                j = this.getLastIndex(this.budgetForm, item) + 1
            }
            this.$set(item, 't_index', j);
            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', `${this.getCommonText('on_del')} itemUpdate`, error.toString());
            }
            this.load = false;
            const lastRowIndex = this.getLastIndex(this.budgetForm, item)
            this.budgetForm.splice(lastRowIndex + 1, 0, item);
            for (let i = j + 1; i < this.budgetForm.length; i++) {
                this.budgetForm[i].index = i;
            }
            region.child.push(item);
        },

        getRegionCode(curItm){
            return typeof curItm.region === 'string' ? curItm.region : curItm.region?.code;
        },

        getLastIndex(list, item){
            for (let i = list.length - 1; i >= 0; i--) {
                const curItm = list[i];
                const regionCode = this.getRegionCode(curItm);
                
                if (regionCode === item.region) {
                    return i
                }
            }
            return -1;
        },

        addProject(region) {
            const item = {};
            this.$set(item, 'id', 0);
            this.$set(item, 'region', region.region.code);
            this.$set(item, 'code', null);
            this.$set(item, 'name_ru', null);
            this.$set(item, 'unit', null);
            this.$set(item, 'unit_code', null);
            this.$set(item, 'distance', 0);
            this.$set(item, 'population', 0);
            this.$set(item, 'beg_date', null);
            this.$set(item, 'end_date', null);
            let j = 0
            if (this.prForm.length != 0){
                j = this.getLastIndex(this.prForm, item) + 1
            }
            this.$set(item, 't_index', j);
            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicates', false);
            this.checkDoubles();
            const lastRowIndex = this.getLastIndex(this.prForm, item)
            this.prForm.splice(lastRowIndex + 1, 0, item);
            for (let i = j + 1; i < this.prForm.length; i++) {
                this.prForm[i].index = i;
            }
            region.child.push(item);
        },

        addRegion(list) {
            if (this.hasEmptyRegions(list)) {
                // Если есть пустой регион, выводим сообщение об ошибке
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('empty_region'))
                return;
            }
            const item = {
                src_region: true,
                region: null,
                child: []
            };
            const form = this.onTab()
            const j = form.length
            this.$set(item, 'index', j);
            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicatesReg', false);
            this.checkDoublesReg();
            list.push(item);
        },

        async loadSpecificData() {
            await this.loadRegions();
            await this.listReg.splice(0);
            this.listReg = JSON.parse(JSON.stringify(this.listRegPr))
            for (const reg of this.listReg) {
                Object.defineProperty(reg, 'text', {
                    get: () => {
                        return reg.value.code + " - " + reg.value[`name_${this.lng}`];
                    }
                });
            }
            await this.loadProjects();
        },

        createTable(elem, region, table) {
            const item = Object.assign({}, elem);
            this.$set(item, 'visible', true);

            if (item.src_region) {
                this.$set(item, 'open', true);
                this.$set(item, 'hasChild', true);
            }
            this.$set(item, 'index', table.length);
            this.$set(table, table.length, item);

            if (item.hasChild) {
                for (const ch of item.child) {
                    this.createTable(ch, item, table);
                }
                //delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        checkDoubles(newVal, item) {
            if (newVal !== undefined) item.name_ru = newVal.trim()
            const firstEntryItm = {};
            this.prForm.forEach((itm, idx) => {
                if (!itm.src_region) {
                    const regionCode = itm.region ? itm.region : 'no code';
                    const prjName = itm.name_ru ? itm.name_ru : 'no name';
                    if (!firstEntryItm.hasOwnProperty(regionCode)) {
                        firstEntryItm[regionCode] = {};
                        firstEntryItm[regionCode][prjName] = idx;
                        itm.checkForDuplicates = false;
                    } else {
                        if (!firstEntryItm[regionCode].hasOwnProperty(prjName)) {
                            firstEntryItm[regionCode][prjName] = idx;
                            itm.checkForDuplicates = false;
                        } else {                        
                            itm.checkForDuplicates = true;
                            const firsDoubleIdx = firstEntryItm[regionCode][prjName];
                            this.prForm[firsDoubleIdx].checkForDuplicates = true;
                        };
                    };
                };
            });
        },

        afterDeleted() {
            this.checkDoubles();
        },

        onChangeDef(value, item, region) {
            this.budgetForm.some((item, i) => {
                if (!item.src_region) {
                    if (i !== item.index) {
                        if (item.region === region && item.name_ru === value.name_ru) {
                            item.checkForDuplicates = true;

                            // если схожий по наименованию запись найдено то выходит из функции
                            return true;
                        } else {
                            item.checkForDuplicates = false;
                        }
                    }
                }
            });
            this.disableProjects(this.budgetForm)
            this.defProject(item);
        },

        filteredProjects(currentItem) {
        // Создаем массив уже выбранных опций
        const selectedOptions = this.budgetForm
            .filter(item => item !== currentItem && item.name_ru)
            .map(item => item.name_ru);

        // Фильтруем варианты, исключая уже выбранные опции
        return currentItem.projects.filter(option => !selectedOptions.includes(option));
    },

        defProject(item) {
            this.$set(item, 'code', item.name_ru.code);
            this.$set(item, 'unit', item.name_ru.unit);
            this.$set(item, 'distance', item.name_ru.distance);
            this.$set(item, 'population', item.name_ru.population);
            this.$set(item, 'cost_amount', 0);
            this.$set(item, 'program', null);
            this.$set(item, 'note', null);
        }, // определение проекта

        async delete(item) {
            if (Array.isArray(item)) {
                item.forEach(itm => this.$set(itm, 'form', this.form.code))
            } else this.$set(item, 'form', this.form.code);
            try {
                this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'cost_amount'));
                const response = await fetch('/api-py/delete-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('one_deleted'));
                    this.deletingAgreementTotalResultHandler(result);
                    this.disableProjects(this.budgetForm)
                    this.loadDataWithoutPpr(this.filterData);
                }
            } catch (error) {
                this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('on_del')} ${error}`);
            } finally {
                this.checkDoublesReg();
            }
        }, // удаление данных

        confirmDelete(message, callback) {
            this.$bvModal.msgBoxConfirm(
                message,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }
            )
            .then(callback)
            .catch(error => {
                this.makeToast('danger', this.getErrText('on_del'), error.toString());
            });
        },

        deleteRegion(row, index) {
            this.confirmDelete(`${this.getFormText('del_confirm')}...`, (value) => {
                    if (value) {
                        const itemsToDel = []
                        const form = this.onTab()
                        for (let i = (form.length - 1); i >= 0; i--) {
                            const ch = form[i];
                            if (!ch.src_region) {
                                const chRegionCode = this.getRegionCode(ch);
                                if (chRegionCode === row.region?.code) {
                                    form.splice(i, 1);
                                    if (ch.id > 0) {
                                        itemsToDel.push(ch)
                                    }
                                }
                            }   
                        }
                        if (itemsToDel.length) this.delete(itemsToDel);
                        if (row.t_index) {
                            index = row.t_index;
                        }
                        form.splice(index, 1);
                        for (let i = index; i < form.length; i++) {
                            if (form[i].t_index){
                                form[i].t_index--;
                            } else {
                                form[i].index--;
                            }
                        }
                        this.checkDoublesReg();
                        this.getDataToCopy();
                    }
                })
        }, // удаляет элемент из формы ввода

        deletePr(row, index) {
            this.confirmDelete(this.getCommonText('on_prj_del'), (value) => {
                    if (value) {
                        if (row.t_index) {
                            index = row.t_index;
                        }
                        this.prForm.splice(index, 1);
                        for (let i = index; i < this.prForm.length; i++) {
                            if (this.prForm[i].t_index){
                                this.prForm[i].t_index--;
                            } else {
                                this.prForm[i].index--;
                            }
                        }
                        for (const item of this.prForm) {
                            if (item.src_region) {
                                if (row.region == item.region.code || row.region.code == item.region.code) {
                                    const indexToRemove = item.child.findIndex(ch => ch.id === row.id);
                                    // Удалим элемент из массива child, если он найден
                                    if (indexToRemove !== -1) {
                                        item.child.splice(indexToRemove, 1);
                                    }
                                }
                            }
                        }
                        if (row.id > 0) {
                            this.delPr(row);
                        } else {
                            this.checkDoubles();
                            this.checkDoublesReg();
                        }
                        this.getDataToCopy();
                    }
                })
        }, // удаляет элемент из формы ввода

        async delPr(item) {
            this.$set(item, 'form', this.form.code);
            try {
                const response = await fetch('/api-py/delete-budget-request-project/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if (response.status === 200) {
                    await this.loadProjects();
                    this.loadDatas();
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('prj_deleted'));
                }
            } catch (error) {
                this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('on_del')}: ${error}`);
            } finally {
                this.checkDoubles();
                this.checkDoublesReg();
            }
        }, // удаление данных

        deleteItem(row, index) {
            this.confirmDelete(`${this.getDecText('del_curr_rec')}...`, (value) => {
                    if (value) {
                        if (row.t_index) {
                            index = row.t_index;
                        }
                        this.budgetForm.splice(index, 1);
                        for (let i = index; i < this.budgetForm.length; i++) {
                            if (this.budgetForm[i].t_index){
                                this.budgetForm[i].t_index--;
                            } else {
                                this.budgetForm[i].index--;
                            }
                        }
                        for (const item of this.budgetForm) {
                            if (!item.src_region) {
                                for (const pr of item.projects) {
                                    if (pr.value.code === row.code) {
                                        this.$set(pr, 'disabled', false);
                                    }
                                }
                            } else {
                                if (row.region == item.region.code || row.region.code == item.region.code) {
                                    const indexToRemove = item.child.findIndex(ch => ch.id === row.id);
                                    // Удалим элемент из массива child, если он найден
                                    if (indexToRemove !== -1) {
                                        item.child.splice(indexToRemove, 1);
                                    }
                                    if (item.child.length == 0) {
                                        //let regIdx = item.index;
                                        this.budgetForm.splice(item.index, 1);
                                        for (let i = item.index; i < this.budgetForm.length; i++) {
                                            this.budgetForm[i].index--;
                                        }
                                    }
                                }
                            }
                        }
                        if (row.id > 0) {
                            this.delete(row);
                        }
                        this.getDataToCopy();
                    }
                })
        }, // удаляет элемент из формы ввода

        onDownloadRep(batch = false, oneGu = false, type = null) {
            this.checkSignatories(batch, oneGu);
            if (batch) {
                this.downloadBatchReports(oneGu, type);
            } else {
                this.downloadRep();
            }
        },

        downloadRepSum() {
            this.isReportUploading = true;
            this.header.lang = this.$i18n.locale;
            this.header.code_modules = this.moduleCode;
            Ax(
                {
                    url: '/api-py/rep_02_159_sum/' + JSON.stringify(this.header),
                    method: 'POST',
                    data: this.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.formSumReport}.xls`);// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.isReportUploading = false;
                },
                (error) => {
                    this.error = error;
                    this.isReportUploading = false;
                }
            );
        },
        downloadRepChange() {
            this.isReportUploading = true;
            this.header.lang = this.$i18n.locale;
            this.header.code_modules = this.moduleCode;
            Ax(
                {
                    url: '/api-py/rep_02_159_change/' + JSON.stringify(this.header),
                    method: 'POST',
                    data: this.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.getFormText('change_rep_2')}.xls`);// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.isReportUploading = false;
                },
                (error) => {
                    this.error = error;
                    this.isReportUploading = false;
                }
            );
        },

        getFiles(data) {
            this.files = data;
        }, // список файлов

        getItem(code, list) {
            for (const item of list) {
                if (item.value.code === code) {
                    return item.value;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        this.region = json.region;
                    });
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} getObl`, error.toString());
            }
        },

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$'); только целые числа
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            this.load = true;
            await this.budgetForm.splice(0);
            await this.tree.splice(0);
            this.loadDataWithoutPpr(this.filterData);

            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                values = await response.json();

                // в этот массив записывается все наименование записей, потом по этому массиву идет проверка
                // если в него есть массив то атрибут checkForDuplicatesReg равен true
                const listCheckDuplicates = [];
                const listCheckDuplicatesReg = [];

                await values.forEach(val => {
                    const item = val;
                    this.$set(item, 'name_ru', this.getItem(val.code_project, this.listProject));
                    this.$set(item, 'region', this.getItem(item.name_ru.region, this.listReg));
                    this.$set(item, 'program', this.getItem(val.code_program, this.listProgram));
                    this.$set(item, 'code', item.name_ru.code);
                    this.$set(item, 'unit', item.name_ru.unit);
                    this.$set(item, 'distance', item.name_ru.distance);
                    this.$set(item, 'population', item.name_ru.population);
                    item['checkForDuplicates'] = !!listCheckDuplicates
                        .find(el => el.get(item['region']['code']) === item.name_ru.name_ru);
                    const pushItem = new Map();
                    pushItem.set(item['region']['code'], item.name_ru.name_ru);
                    listCheckDuplicates.push(pushItem);

                    this.$set(item, 'files', val.files);
                    this.$set(item, 'row_files', val.row_files);

                    const region = this.tree.filter(function (row) {
                        if (row.region.code === item.region.code) { return row; }
                    });
                    if (region.length > 0) {
                        this.$set(item, 'projects', region[0].projects);
                        region[0].child.push(item);
                    } else {
                        const regItem = {
                            src_region: true,
                            region: item.region,
                            child: []
                        };
                        regItem['checkForDuplicatesReg'] = !!listCheckDuplicatesReg.find(el => el === item.region.code)
                        this.regProjects(item);
                        regItem.child.push(item);
                        this.regProjects(regItem);
                        this.tree.push(regItem);

                        listCheckDuplicatesReg.push(item.region.code)
                    }
                });
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas`, error.toString());
                return;
            }

            for (const item of this.tree) {
                this.createTable(item, null, this.budgetForm);
            }
            this.load = false;
            this.getDataToCopy();
        }, // загрузка данных

        async loadUnits() {
            const codes = ['001.006.008', '008.642', '002.055'];
            try {
                const response = await fetch('/api-py/dictionary_code/unit/' + codes);
                const items = await response.json();
                for (const row of items) {
                    const el = {};
                    this.$set(el, 'value', row);
                    Object.defineProperty(el, 'text', {
                        get: () => {
                            return this.getUnitLockName(row);
                        }
                    });
                    this.listUnit.push(el);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadUnits`, error.toString());
            }
        }, // справочник единиц измерения

        getUnitLockName(item) {
            if (this.lng === 'kk') return item.name_kz;
            return item.national_symbol;
        },

        async loadProjects() {
            await this.prForm.splice(0);
            await this.prTree.splice(0);
            await this.listProject.splice(0);
            try {
                const response = await fetch('/api-py/get-projects/' + JSON.stringify(this.header));
                const result = await response.json();
                const values = this.filterDistricts(result, 'region');
                // в этот массив записывается все наименование записей, потом по этому массиву идет проверка
                // если в него есть массив то атрибут checkForDuplicatesReg равен true
                const listCheckDuplicates = [];
                const listCheckDuplicatesReg = [];

                for (const row of values) {
                    // формируем дерево проектов
                    this.$set(row, 'unit', this.getItem(row.unit_code, this.listUnit));

                    // список проектов
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.name_ru);

                    row['checkForDuplicates'] = !!listCheckDuplicates
                        .find(e => e.get(row['region']) === row.name_ru);
                    const pushItem = new Map();
                    pushItem.set(row['region'], row.name_ru);
                    listCheckDuplicates.push(pushItem);

                    this.listProject.push(el);

                    // формируем дерево проектов
                    const region = this.prTree.filter(function (item) {
                        if (item.region.code === row.region) { return item; }
                    });
                    if (region.length > 0) {
                        region[0].child.push(row);
                    } else {
                        const reg = this.getItem(row.region, this.listReg);
                        if (reg !== null) {
                            const item = {
                                src_region: true,
                                region: Object.assign({}, reg),
                                child: []
                            };
                            item['checkForDuplicatesReg'] = !!listCheckDuplicatesReg.find(el => el === row.region.code)
                            item.child.push(row);
                            this.prTree.push(item);

                            listCheckDuplicatesReg.push(item.region.code)
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadProjects`, error.toString());
                return;
            }

            for (const item of this.prTree) {
                this.createTable(item, null, this.prForm);
            }
        }, // загрузка проектов

        disableProjects(formData) {
            if (!formData) return;
            for (const item of formData) {
                if (!item.src_region) {
                    for (const pr of item.projects) {
                        const isPrjExist = formData.findIndex(itm => itm.code === pr.value.code) !== -1;
                        this.$set(pr, 'disabled', isPrjExist);
                    }
                }
            }
        }, // дисейблит проекты которые уже есть 

        async loadPrograms() {
            try {
                const response = await fetch('/api-py/dict_program/');
                const items = await response.json();
                for (const row of items) {
                    const el = {};
                    this.$set(el, 'value', row);
                    Object.defineProperty(el, 'text', {
                        get: () => {
                            return this.getLocProgramName(row);        
                        }
                    });
                    this.listProgram.push(el);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadPrograms`, error.toString());
            }
        }, // справочник единиц измерения

        getLocProgramName(item) {
            if (!item) return null;
            if (this.lng === 'kk') return item.name_kz;
            return item.name_ru;   
        },

        checkDoublesReg() {
            const list = this.onTab();
            const firstEntryItm = {}
            list.forEach((itm, idx) => {
                if (itm.src_region) {
                    const code = itm.region?.code === undefined || itm.region.code === null ? "no code" : itm.region.code
                    if (!firstEntryItm.hasOwnProperty(code)) {
                        firstEntryItm[code] = idx;
                        itm.checkForDuplicatesReg = false;
                    } else {
                        itm.checkForDuplicatesReg = true;
                        const firsDoubleIdx = firstEntryItm[code];
                        list[firsDoubleIdx].checkForDuplicatesReg = true;
                    }
                };
            });
        },

        onChangeReg(value, data) {
            const form = this.onTab();
            const item = data.item ? data.item : data
            this.hasRegionProjects(item)
            this.checkDoublesReg();
            this.regProjects(item);
            if (this.tabIndex == 0) {
                this.clearForm(form);
            }
        },

        clearForm(form) {
            for (const itm of form) {
                if (!itm.src_region){
                    let del = 0
                    for (const reg of form.filter(r => r.src_region)) {
                        if (itm.region == reg.region.code){
                            del++;
                        }
                        
                    }
                    if (del == 0) {
                        if (itm.t_index) {
                            itm.index = itm.t_index;
                        }
                        this.prForm.splice(itm.index, 1);
                        for (let i = itm.index; i < this.prForm.length; i++) {
                            if (this.prForm[i].t_index){
                                this.prForm[i].t_index--;
                            } else {
                                this.prForm[i].index--;
                            }
                        }
                    }
                }
            }
        },

        regProjects(parent) {
            const region = this.prTree.filter(function (item) {
                if (item.region.code === parent.region.code) { return item; }
            });

            if (region.length > 0) {
                const list = [];
                for (const row of region[0].child) {
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.name_ru);
                    list.push(el);
                }
                this.$set(parent, 'projects', list);
            } else {
                delete parent.projects;
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        numRRD(region) {
            let num = 0;
            for (const row of this.prjForm) {
                if (!row.src_region && (region === row.region)) {
                    num++;
                }
            }
            return ++num;
        }, // генерация кода проекта

        prepareSave() {
            if (this.tabIndex === 0) {
                this.projectForSave();
            } else {
                this.prepareForSave();
            }
        },

        prepareForSave() {
            const values = [];
            let error = false;
            let rowNumber = 1
            for (const row of this.budgetForm) {
                if (!row.src_region) {
                    if (!this.isItemValid(row, rowNumber)) return;
                    if (row.cost_amount > 0 && !row.checkForDuplicates) {
                        const item = Object.assign({}, this.header);
                        this.$set(item, 'id', row.id);
                        this.$set(item, 'code_project', row.code);
                        this.$set(item, 'cost_amount', parseFloat(row.cost_amount));
                        this.$set(item, 'code_program', (row.program !== null ? row.program.code : null));
                        this.$set(item, 'note', row.note);
                        this.$set(item, 'row_files', row.row_files);
                        values.push(item);
                    } else {
                        error = true;
                    }
                }
                else if (!error) {
                    const isHasChild = row.child.length > 0;
                    error = !isHasChild;
                }
                ++rowNumber;
            }
            if (this.budgetForm.find(item => item.checkForDuplicatesReg === true || item.checkForDuplicates === true)) {
                this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('not_valid')}. ${this.getErrText('not_saved')}!`);
            }
            else {
                if (values.length && this.variantAttribute) {
                    this.save(values, error);
                }
                else {
                    this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
                }
            }
        },

        isItemValid(row, rowNumber) {
            if (this.isEmpty(row.region)) return this.validationErrorMessage(this.getCommonText('region'), rowNumber);
            if (this.isEmpty(row.name_ru)) return this.validationErrorMessage(this.getCommonText('name'), rowNumber);
            if (this.isEmpty(row.unit)) return this.validationErrorMessage(this.getUnitsText('measure'), rowNumber);
            if (['001.006.008', '002.055'].includes(row.unit.code) && (row.distance === 0))  return this.validationErrorMessage(this.getCommonText('length'), rowNumber);
            if (row.cost_amount <= 0) return this.validationErrorMessage(this.getCommonText('spnds_sum'), rowNumber);
            if (row.checkForDuplicatesReg === true || row.checkForDuplicates === true) {
                this.makeToast('warning', this.getErrText('not_saved'), this.getErrText('doubles_in_row', {rowNumber: rowNumber}));
                return false
            }
            return true
        },

        projectForSave() {
            const values = [];
            let error = false;
            let rowNumber = 1
            for (const row of this.prForm) {
                if (!row.src_region) {
                    if (!this.isDataValid(row, rowNumber)) return;
                    const item = Object.assign({}, this.header);
                    this.$set(item, 'id', row.id);
                    this.$set(item, 'code', row.code);
                    this.$set(item, 'region', row.region);
                    this.$set(item, 'name_ru', row.name_ru);
                    this.$set(item, 'unit_code', row.unit.code);
                    this.$set(item, 'distance', row.distance);
                    this.$set(item, 'population', row.population);
                    this.$set(item, 'beg_date', row.beg_date);
                    this.$set(item, 'end_date', row.end_date);
                    values.push(item);
                }
                else if (!error) {
                    const isHasChild = row.child.length > 0;
                    error = !isHasChild;
                }
                ++rowNumber;
            }
            if (values.length && this.variantAttribute) {
                this.savePr(values, error);
            }
            else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        }, // подготовка проектов для сохранения

        async save(values, error) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const response = await fetch('/api-py/save-brform' + this.form.code + '/' +
                    JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    if (error) {
                        this.makeToast('warning', this.getErrText('msg'), this.getErrText('wrong_data_not_saved'));
                    } else {
                        this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                        this.deletingAgreementTotalResultHandler(result);
                    }
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async savePr(values, error) {
            try {
                this.isLoadProject = true;
                const response = await fetch('/api-py/save-budget-request-project/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (error) {
                        this.makeToast('warning', this.getErrText('msg'), this.getErrText('wrong_data_not_saved'));
                    } else {
                        this.makeToast('success', this.getErrText('msg'), this.getErrText('prj_saved'));
                    }
                    this.loadDatas();
                    await this.loadProjects();
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('prj_save_err'));
            } finally {
                this.isLoadProject = false;
            }
        }, // сохранение данных
        
    getFilteredPrForm(prForm, region) {
        let filteredPrForm = []
        if (region.region) {
            filteredPrForm = prForm.filter(item => ((item.src_region && item.region === region.region) || item.region === region.region.code));
        } else {
            filteredPrForm = prForm.filter(item => (item.src_region && !item.region));
        }
        return filteredPrForm;
    },

    getFilteredBudgetForm(budgetForm, region) {
        let filteredBudgetForm = []
        if (region.region) {
            filteredBudgetForm = budgetForm.filter(item => {
                const chRegionCode = this.getRegionCode(item);
                return ((item.src_region && item.region === region.region) || chRegionCode === region.region.code);
            }); 
        } else {
            filteredBudgetForm = budgetForm.filter(item => (item.src_region && !item.region));
        }
        return filteredBudgetForm;
    }
},
    computed: {
        formReport() {
            const report = this.$t("modules.budget_request.calculation_forms.reports.report_headers.form_02_159.report");
            return `${report} ${"02-159 (339)"}`;
        },
        formSumReport() {
            const sum_report = this.$t("modules.budget_request.calculation_forms.reports.report_headers.form_02_159.summary_report");
            return `${sum_report} ${"02-159 (339)"}`;
        },
        formChangeReport() {
            const report = this.$t("modules.budget_request.calculation_forms.reports.report_headers.form_02_159.report");
            const change_report = this.$t("modules.budget_request.calculation_forms.reports.report_headers.form_02_159.change_report");
            return `${report} ${"02-159 (339)"} ${change_report}`;
        },
        distance() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (!row.src_region) {
                    sum += row.distance ? parseFloat(row.distance) : 0;
                }
            }
            return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
        },
        population() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (!row.src_region) {
                    sum += row.population ? parseFloat(row.population) : 0;
                }
            }
            return sum;
        },
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (!row.src_region) {
                    sum += row.cost_amount ? parseFloat(row.cost_amount) : 0;
                }
            }
            return Math.ceil(sum);
        },

        isDownloadRepBtnVisible() {
            return this.header && (this.header.data_type === '3' || this.header.data_type === '4');
        },

        getFormText() {
            return this.setFormText('form_02_159_1.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'code',
                        label: this.getFormText('prj_code')
                    },
                    {
                        key: 'name_ru',
                        label: this.getFormText('reg_prj_name')
                    },
                    {
                        key: 'unit',
                        label: this.getUnitsText('unit')
                    },
                    {
                        key: 'distance',
                        label: this.getFormText('length')
                    },
                    {
                        key: 'population',
                        label: this.getFormText('population')
                    },
                    {
                        key: 'cost_amount',
                        label: this.getFormText('expense_sum')
                    },
                    {
                        key: 'program',
                        label: this.getFormText('financing')
                    },
                    {
                        key: 'note',
                        label: this.getCommonText('notes')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },

        prFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'code',
                        label: this.getFormText('prj_code')
                    },
                    {
                        key: 'name_ru',
                        label: this.getFormText('reg_prj_name')
                    },
                    {
                        key: 'unit',
                        label: this.getUnitsText('unit')
                    },
                    {
                        key: 'distance',
                        label: this.getFormText('length')
                    },
                    {
                        key: 'population',
                        label: this.getFormText('population')
                    },
                    {
                        key: 'beg_date',
                        label: this.getCommonText('beg_date')
                    },
                    {
                        key: 'end_date',
                        label: this.getCommonText('end_date')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },
        buttonsText() {
            return this.$t("modules.budget.budget_request");
        },
        batchReportEducationDepText() {
            if (this.$store.state.isDownloadButtonEnabled) return this.buttonsText.uploadIsProgress;
            return this.buttonsText.batchUploaEduButton;
        },
        isABPReportAvailable() {
            return this.guListLen > 1 && !this.isGkkpMode && this.isHeadGu;
        }
    }
};
</script>

<style scoped>
.filter-actions-flex {
    display: flex;
}

.entire-row {
    display: block flow;
    width: 100%;
    margin:auto;
    height: auto;
    position: absolute !important;
    background-color: #E9F4FE;
}

.td-numbering{
    padding: 3px 10px!important;
    text-align: center;
    font-size: 0.7rem !important;
    font-weight: 700!important;
    color: #678CA3!important;
}

.green{
    background-color: #EFF8E7;
}

.red{
    background-color: #F6DFE4;
}

.purple{
    background-color: #D9D2FE;
}

.orange{
    background-color: #FBF3DB;
}

.blue{
    background-color: #D7EAFE;
}

.light{
    background-color: #F7F9FC;
}

.region-row{
    width: 100%;
    height: auto;
    background-color: #E9F4FE;
}

.add-button{
    padding: 10px 20px;
    background-color: #F7F9FC;
}
.add-project{
    text-decoration: underline;
    color: green;
    text-transform: capitalize;
    padding: 5px;
    
}
</style>